import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/SEO'

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
    query {
      wpPage(slug: {eq: "policy"}) {
        content
      }
    }
    `
  )
  const content = data.wpPage.content
  const policyText = '<p>Review our website policy.</p><p><a href="/" class="nav_link">Return to the main website</a>.</p>'

  return (
    <>
      <SEO title='Policy' />
      <Layout content={policyText}>
        <div className='policy-container'>
          <div  dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
